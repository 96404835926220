<template>
  <el-select
    v-model="type"
    placeholder="请选择意向程度"
    style="width: 200px"
    @change="changeValue"
    filterable
    size="small"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  props: {
    type: String,
  },
  data() {
    return {
      options: [
        {
          label: "A类意向",
          value: "A类意向",
        },
        {
          label: "B类意向",
          value: "B类意向",
        },
        {
          label: "C类意向",
          value: "C类意向",
        },
        {
          label: "D类意向",
          value: "D类意向",
        },
        {
          label: "E类意向",
          value: "E类意向",
        },
      ],
    };
  },
  methods: {
    changeValue(val) {
      this.$emit("update:type", this.type);
      this.$emit("success", this.type);
    },
    clearValue() {
      this.type = "";
    },
  },
};
</script>

<style scoped lang="less"></style>
